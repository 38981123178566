<template>
  <div>
    <div v-if="list.length > 0">
      <div
        class="mySalary_list"
        v-for="(item, index) in list"
        :key="index"
        @click="toSalaryInfo"
      >
        <div class="mySalary_span">
          {{ item.person_name }}的{{ item.month }}月薪资账单
        </div>
        <div class="mySalary_time">{{ item.create_time }}</div>
      </div>
      <van-row>
        <van-col span="18" offset="3">
          <div class="margin_div_bottom">
            <van-loading size="48rpx" v-if="!isBottom">加载中...</van-loading>
          </div>
          <div v-if="isBottom" class="margin_div_bottom">暂无更多数据了</div>
        </van-col>
      </van-row>
    </div>
    <div v-else class="noStatus">暂无相关数据</div>
  </div>
</template>
<script>
// import { formatMinute } from "../utils/tool";
export default {
  data() {
    return {
      list: [],
      pid: "",
      isBottom: false,
      pageNum: 1,
    };
  },
  mounted() {
    let pid = localStorage.getItem("pid");
    let id = localStorage.getItem("id");
    this.pid = pid;
    // 测试
    // this.pid=53738;
    this.id = id;
  },
  methods: {
    //社保详情
    toSalaryInfo(val) {
      this.$router.push({ name: "salaryInfo", params: { id: val.id } });
    },
  },
};
</script>
<style scoped>
.mySalary_list {
  border-bottom: 0.0625rem solid #ddd;
  padding: 0.3125rem 0.9375rem 0.625rem 0.9375rem;
  box-sizing: border-box;
  height: 3.75rem;
}
.mySalary_span {
  line-height: 1.5625rem;
}
.mySalary_time {
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 0.3125rem;
}
.noStatus {
  text-align: center;
  margin-top: 1.25rem;
}
.margin_view_bottom {
  text-align: center;
  margin: 1.25rem 0;
}
</style>
<style>
</style>
